<template>
  <div>
    <div class="loading" v-if="isLoading == true">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
        </div>
    </div>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="name" slot-scope="prop">
          <span>{{ prop.rowData.name }}</span>
        </template>
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button class="btn btn-success" @click="onAction('detail-item', prop.rowData)">Detail</button>&nbsp;
            <button class="btn btn-danger" @click="onAction('delete-item', prop.rowData)" v-if="prop.rowData.can_delete == true"><i class="fa fa-trash-o"></i></button>&nbsp;
          </div>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import accounting from 'accounting'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        isLoading: false,
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `pricing-index`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'actived_at',
            title: 'Actived At'
          },
          {
            name: 'expired_at',
            title: 'Expired At'
          },
          {
            name: 'price_type',
            title: 'Price Type',
            formatter: this.margin
          },
          {
            name: 'duration',
            title: 'Duration',
            formatter: this.duration
          },
          {
            name: 'created_at',
            sortField: 'created_at',
            title: 'Created At'
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    methods: {
      margin (val) {
        return val != null ? val.toUpperCase() : '-'
      },
      duration (val) {
        return val != null ? val + ' menit' : '-'
      },
      // activate (val) {
      //   return val == true ? 'Yes' : 'No'
      // },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      hours(value){
        return value != null ? value + ' Jam' : 'Tidak ditemukan'
      },
      formatNumber (value) {
        return value != null ? 'Rp.'+accounting.formatNumber(value, 2) : '-'
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'detail-item'){
          this.$router.push({name: 'Detail Pricing', params: { id: data.hashedId}})
        }else{
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Custom Pricing!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {

                this.$http.delete(`pricing-delete/` + data.hashedId)
                .then(() => {
                  this.$swal.fire(
                    'Deleted!',
                    'Custom pricing Order has been deleted.',
                    'success'
                  ).then(() => {
                    location.reload();
                  })
                }).catch((error) => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                'Cancelled',
                'Custom pricing was successfully canceled for deletion!',
                'error'
              )
            }
          })
        }
      },
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
