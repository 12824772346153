<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                Gold Pricing Treasury
            </b-card-header>
            <b-card-body>
              <b-row class="mb-4">
                <b-col>
                  <router-link :to="{name: 'Add Pricing'}" class="btn btn-primary" v-if="$can('create_pricing')"><i class="fa fa-plus"></i> Create Pricing</router-link>
                </b-col>
              </b-row>
                <div class="loading" v-if="isLoading == true">
                    <div class="sk-three-bounce">
                        <div class="sk-child sk-bounce1"></div>
                        <div class="sk-child sk-bounce2"></div>
                        <div class="sk-child sk-bounce3"></div>
                    </div>
                </div>
                <b-row class="mb-5">
                    <b-col>
                        <h4 class="mb-5">Harga Emas Hari ini : {{ this.goldRate }}</h4>
                        <b-select
                          v-model="menu"
                          :options="[
                            { value: 'number', text: 'Harga Emas Per 1 gram'},
                            { value: 'decimal', text: 'Harga Emas Per 0.01 gram'},
                          ]"
                        ></b-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <h6>Netdania (Active Gold Source)</h6>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-primary p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Margin Buy</div>
                            <div class="h6 text-primary mb-0">USD : {{ this.usdCurrencyBuy}}$</div>
                            <div class="h6 text-primary mb-0">IDR : {{ formatedRupiah(this.buying_rate) }}/1 g (1$ = {{ formatedRupiah(this.rupiah) }} IDR)</div>
                            <!-- <div class="text-muted text-uppercase font-weight-bold font-xs">PRICE SOURCE SAAT INI</div> -->
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Margin Sell</div>
                            <div class="h6 text-primary mb-0">USD : {{ this.usdCurrencySell}}$</div>
                            <div class="h6 text-primary mb-0">IDR : {{ formatedRupiah(this.selling_rate)}}/1 g (1$ = {{ formatedRupiah(this.rupiah) }} IDR)</div>
                            <!-- <div class="text-muted text-uppercase font-weight-bold font-xs">PRICE SOURCE SAAT INI</div> -->
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Gold Spread</div>
                            <div class="h5 text-primary mb-0">{{ Math.round((((this.buying_rate - this.selling_rate) / this.buying_rate * 100) + Number.EPSILON) * 100) / 100 }}%</div>
                            <!-- <div class="text-muted text-uppercase font-weight-bold font-xs">PRICE SOURCE SAAT INI</div> -->
                        </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <!-- <b-row>
                    <b-col>
                        <h6>gold.org</h6>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-primary p-4 font-2xl mr-3 float-left"></i>
                            <div class="h5 text-primary mb-0 pt-3">{{ this.buyPriceRupiah | currency }}</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Buy</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="6">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="h5 text-info mb-0 pt-3">{{ this.sellPriceRupiah | currency }}</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Sell</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col>
                        <h6>Price Before Margin</h6>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-primary p-4 font-2xl mr-3 float-left"></i>
                            <div class="h5 text-primary mb-0 pt-3">{{ this.gold_rate.buying_rate_pure | currency }}</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Buy</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="6">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="h5 text-info mb-0 pt-3">{{ this.gold_rate.selling_rate_pure | currency }}</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Sell</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <h6>Price After Margin</h6>
                    </b-col>
                </b-row>
                <!-- <b-row>
                    <b-col sm="6">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-primary p-4 font-2xl mr-3 float-left"></i>
                            <div class="h5 text-primary mb-0 pt-3">{{ this.gold_rate.buying_rate | currency}}</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Buy</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="6">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="h5 text-info mb-0 pt-3">{{ this.gold_rate.selling_rate | currency}}</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Sell</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                </b-row> -->
                <b-row v-if="this.menu == 'number'">
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-money bg-success p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Margin Buy</div>
                            <div class="h5 text-primary mb-0">IDR : {{ formatedRupiah(this.buying_rate) }}/1 g</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Harga beli Emas saat ini</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-money bg-danger p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Margin Sell</div>
                            <div class="h5 text-info mb-0">IDR : {{ formatedRupiah(this.selling_rate)}}/1 g</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Harga Jual Emas saat ini</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Gold Spread</div>
                            <div class="h5 text-primary mb-0">{{ Math.round((((this.buying_rate - this.selling_rate) / this.buying_rate * 100) + Number.EPSILON) * 100) / 100 }}%</div>
                            <!-- <div class="text-muted text-uppercase font-weight-bold font-xs">PRICE SOURCE SAAT INI</div> -->
                        </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-if="this.menu == 'decimal'">
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-money bg-success p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Margin Buy</div>
                            <div class="h5 text-primary mb-0">IDR : {{ this.buying_rate_zero | currency }}/0.01 g</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Harga beli Emas saat ini</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-money bg-danger p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Margin Sell</div>
                            <div class="h5 text-info mb-0">IDR : {{ this.selling_rate_zero | currency }}/0.01 g</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Harga Jual Emas saat ini</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Gold Spread</div>
                            <div class="h5 text-primary mb-0">{{ Math.round((((this.buying_rate_zero - this.selling_rate_zero) / this.buying_rate_zero * 100) + Number.EPSILON) * 100) / 100 }}%</div>
                            <!-- <div class="text-muted text-uppercase font-weight-bold font-xs">PRICE SOURCE SAAT INI</div> -->
                        </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row class="mb-4">
                  <b-col>
                    <h3>Master Pricing</h3>
                    <b-row>
                      <b-col sm="4">
                        <h6>Created By</h6>
                        <div class="mb-3" v-if="this.actived_by == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.actived_by}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Type</h6>
                        <div class="mb-3" v-if="this.pricing.type == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.type.toUpperCase()}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Created at</h6>
                        <div class="mb-3" v-if="this.pricing.created_at == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.created_at.toUpperCase()}}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.pricing.type == 'combination'">
                      <b-col sm="4">
                        <h6>Margin Buy (%)</h6>
                        <div class="mb-3" v-if="this.pricing.margin_ask == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.margin_ask}}%</strong></div>
                        <h6>Margin Buy IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.pricing.margin_ask_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.margin_ask_combination | currency}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Margin Sell (%)</h6>
                        <div class="mb-3" v-if="this.pricing.margin_bid == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.margin_bid}}%</strong></div>
                        <h6>Margin Sell IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.pricing.margin_bid_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.margin_bid_combination | currency}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Trading Fee (%)</h6>
                        <div class="mb-3" v-if="this.pricing.trading_fee == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.trading_fee}}%</strong></div>
                        <h6>Trading Fee IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.pricing.trading_fee_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.trading_fee_combination | currency}}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.pricing.type == 'amount'">
                      <b-col sm="4">
                        <h6>Margin Buy IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.pricing.margin_ask_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.margin_ask_combination | currency}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Margin Sell IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.pricing.margin_bid_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.margin_bid_combination | currency}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Trading Fee IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.pricing.trading_fee_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.trading_fee_combination | currency}}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.pricing.type == 'percentage'">
                      <b-col sm="4">
                        <h6>Margin Buy (%)</h6>
                        <div class="mb-3" v-if="this.pricing.margin_ask == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.margin_ask}}%</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Margin Sell (%)</h6>
                        <div class="mb-3" v-if="this.pricing.margin_bid == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.margin_bid}}%</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Trading Fee (%)</h6>
                        <div class="mb-3" v-if="this.pricing.trading_fee == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.pricing.trading_fee}}%</strong></div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h3>Custom Pricing</h3>
                      <b-row class="mb-3" v-if="$can('export')">
                        <b-col sm>
                            <div>
                                <b-form-group
                                    class="mb-3"
                                    label="Export Pricing :"
                                    label-for="daterange"
                                >
                                    <b-input-group
                                    >
                                        <b-input-group-prepend is-text>
                                            <i class="fa fa-calendar"></i>
                                        </b-input-group-prepend>
                                        <range-picker id="daterange" :start="startDate" :end="endDate" @picker="changeDate"></range-picker>
                                        <b-input-group-append>
                                            <button type="button" class="btn btn-success" @click="exportTable" >Export</button>&nbsp;
                                            <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                        </b-col>
                    </b-row>
                    <pricing-table></pricing-table>
                  </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import PricingTable from '@/views/pages/pricing/Table'
import Pusher from 'pusher-js' // import Pusher
import accounting from 'accounting'
import moment from 'moment'
import axios from 'axios'

let startDate = '';
let endDate = '';

export default {
    name: 'Index-pricing',
    components: {
      PricingTable,
    },
    data() {
        return {
            startDate: '',
            endDate: '',
            pricing: '',
            rupiah: '',
            usdCurrencyBuy: 0,
            usdCurrencySell: 0,
            usdSell: '',
            gold_rate: '',
            goldRate: '',
            buying_rate: '',
            selling_rate: '',
            buying_rate_zero: '',
            selling_rate_zero: '',
            buyPriceRupiah: '',
            sellPriceRupiah: '',
            actived_by: '',
            menu: 'number',
            isLoading: false
        }
    },
    created () {
      this.$http
      .get(`pricing`)
      .then((response) => {
        this.pricing = response.data.meta.data.price;
        this.gold_rate = response.data.meta.data.gold_rate;
        this.buying_rate = this.gold_rate.buying_rate;
        this.selling_rate = this.gold_rate.selling_rate;
        this.buying_rate_zero = this.gold_rate.buying_rate_zero;
        this.selling_rate_zero = this.gold_rate.selling_rate_zero;
        // this.buyPriceRupiah = response.data.meta.data.buyPriceRupiahOrg;
        // this.sellPriceRupiah = response.data.meta.data.sellPriceRupiahOrg;
        this.rupiah = this.gold_rate.rupiahCurrency;
        this.usdCurrencyBuy = this.gold_rate.usdCurrencyBuy;
        this.usdCurrencySell = this.gold_rate.usdCurrencySell;
        this.usdSell = this.gold_rate.sellPrice;
        this.actived_by = response.data.meta.data.actived_by;
        this.goldRate = moment(this.pricing.gold_rate.created_at).format('DD/MM/YYYY HH:mm')
      })
      .catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      })

      this.subscribe()
    },
    methods: {
      formatNumber (value) {
        return value != null ? 'Rp.'+accounting.formatNumber(value, 2) : '-'
      },
      formatedRupiah(number){
        if (number != null) {
          var reverse = number.toString().split('').reverse().join(''),
          thousand = reverse.match(/\d{1,3}/g);
          thousand = thousand.join('.').split('').reverse().join('');
          return 'Rp. ' + thousand;
        } else {
          return 'Rp. ' + 0;
        }

      },
      subscribe () {
        let that = this;
        let pusher = new Pusher('52e99bd2c3c42e577e13', { cluster: 'ap1', forceTLS: true })
        pusher.subscribe('gold-rate')
        pusher.bind('gold-rate-event', data => {
          let buying = data.buying_rate
          let selling = data.selling_rate
          that.buying_rate = buying * 1000
          that.selling_rate = selling * 1000
          that.buying_rate_zero = buying / 0.1
          that.selling_rate_zero = selling / 0.1
          that.goldRate = moment(data.created_at).format('DD/MM/YYYY HH:mm')
          let rupiahToUsdBuy = buying * 1000
          let rupiahToUsdSell = selling * 1000
          let usdCurrencyBuy = 0;
          let usdCurrencySell = 0;
          axios.get('https://apilayer.net/api/convert?access_key=a02f25ae78c0c8ed005ded2d91f53b23&from=USD&to=IDR&amount=1').then((result) =>{
            that.rupiahCurrency = Math.ceil(result.data.result);
            let usdConvertBuy = rupiahToUsdBuy / that.rupiahCurrency;
            let usdConvertSell = rupiahToUsdSell / that.rupiahCurrency;
            usdCurrencyBuy = Math.round((usdConvertBuy + Number.EPSILON) * 100) / 100;
            usdCurrencySell = Math.round((usdConvertSell + Number.EPSILON) * 100) / 100;
            that.usdCurrencyBuy = usdCurrencyBuy
            that.usdCurrencySell = usdCurrencySell
          })
        })
      },
      changeDate (value) {
          this.startDate = value.startDate;
          this.endDate = value.endDate;
      },
      exportTable() {
          this.isLoading = true
          if (this.startDate != '' && this.endDate != '') {
              startDate = this.startDate;
              endDate = this.endDate;
          }else{
              endDate = this.$setDate.format('YYYY-MM-DD');
              startDate = this.$setDate.subtract(29, 'days').format('YYYY-MM-DD');
          }
          this.$http.get(`pricings/` + startDate + `/` + endDate)
          .then((result) => {
              this.isLoading = false
              const  exportPath = result.data.meta.data;
              window.location.href = exportPath;
          }).catch((error) => {
              if (error.response) {
                  this.isLoading = false
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
              }
          })
      },
      resetDateFilter () {
          this.startDate = ''
          this.endDate = ''
      },
    },
}
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
